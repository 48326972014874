<!--  -->
<template>
  <div class='xuexi round bj-col-white pad' v-loading="loading">
    <div class="heard">
      <h3>公需课程<span>(学习)</span></h3>
    </div>
    <div class="nav">
      <div class="prompt">
        <div class="year">
          <span>选择年份</span>
          <el-select v-model="year" placeholder="请选择" @change="changeYear" class="picker">
            <el-option
              v-for="item in options"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </div>
        <div>学时：根据人社部《专业技术人员继续教育规定》关于公需科目学习要求，所选课程不少于30学时。</div>
        <div>学分：公需课边学边弹题，学完授予10学分，方可打印学分证明。</div>
      </div>
      <div class="code">
        <img src="../../assets/img/code/study-h5.png" alt="">
        <span>手机学习请扫公众号二维码</span>
      </div>
    </div>

    <template>
      <div class="table">
        <div @click="handleTable(0)">
          <h4 :style="{color: type == 0 ? '#06B487' : ''}">{{info.all || 0}}</h4>
          <div :style="{color: type == 0 ? '#06B487' : ''}">全部课程</div>
        </div>
        <div @click="handleTable(1)">
          <h4 :style="{color: type == 1 ? '#06B487' : ''}">{{info.over || 0}}</h4>
          <div :style="{color: type == 1 ? '#06B487' : ''}">已完成</div>
        </div>
        <div @click="handleTable(2)">
          <h4 :style="{color: type == 2 ? '#06B487' : ''}">{{info.nover || 0}}</h4>
          <div :style="{color: type == 2 ? '#06B487' : ''}">未完成</div>
        </div>
      </div>
      
      <div class="list">
        <kc-array :list="list" :year="Number(year)"></kc-array>
      </div>
    </template>

  </div>
</template>

<script>
import Api from '../../api/gongxu'
import KcArray from '../components/KcArray.vue'

export default {
  components: {
    KcArray,
  },
  data() {
    return {
      year: '',
      year_copy: '',
      options: [],
      type: 0,
      info: {},
      list: [],
      loading: true,
    };
  },
  computed: {},
  watch: {
    
  },
  created() {
  
  },
  mounted() {
    this.getYears()
  },
  //方法集合
  methods: {
    getYears () {
      Api.getYears().then(res => {
        if (res.data.code == 1) {
          this.options =  res.data.data.years
          this.year = res.data.data.year
          this.year_copy = this.year
          this.getStatus()
        } else {
          this.$message.error('公需科目年份目录获取失败')
        }
      })
    },
    changeYear () {
      localStorage.setItem("year", this.year)
      Api.setYears({
        year: this.year
      }).then(res => {
        if (res.data.code == 1) {
          this.year_copy = this.year
          this.setUserInfo()
          this.getStatus()
        } else {
          this.year = this.year_copy
          this.$message.error("公需科目年份切换失败")
        }
      })
    },
    getStatus () {
      if (this.year == 2020) {
        this.$alert('2020年公需科目无课程学习，广东省执业药师注册从2021年开始提供公需科目。', '温馨提示', {
          confirmButtonText: '确认',
          callback: () => {
            this.$emit('noAuth')
          }
        });
        return;
      }
      Api.getStatus({
        year: this.year
      }).then(res => {
        if (res.data.code == 1) {
          this.type = 0
          this.info = res.data.data
          this.getCourseList()
        } else {
          this.$message.error('课程学习进度获取失败')
        }
      })
    },
    handleTable (type) {
      this.type = type
      this.getCourseList()
    },
    getCourseList () {
      Api.getCourseList({
        year: this.year,
        type: this.type
      }).then(res => {
        this.loading = false
        if (res.data.code == 1) {
          this.list = res.data.data
          if (res.data.data.length < 1) {
            this.$router.replace({name: 'Maike'})
          }
        } else {
          this.$message.error("公需科目课程列表获取失败")
        }
      })
    },
  },
}
</script>

<style lang='less' scoped>
.xuexi {
  .heard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
      font-size: 18px;
    }
    span {
      font-size: 16px;
      display: inline-block;
      margin-left: 5px;
    }
  }
  .nav {
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0 10px;
    .tabs {
      width: 70%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .tab {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        .icon {
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(20%, -20%);
          color: @default;
        }
      }
      .line {
        width: 50px;
        height: 2px;
        background-color: @default;
      }
      .ban {
        background-color: #E5E5E5;
      }
    }
    .code {
      padding: 30px 0 30px 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-left: 1px solid #D8D8D8;
      img {
        width: 120px;
      }
    }
  }
  .prompt {
    margin-bottom: 30px;
    > div {
      margin-top: 10px;
      span {
        color: red;
      }
    }
    .year {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      > span {
        color: #000;
        margin-right: 15px;
        font-weight: bold;
      }
    }
  }
  .table {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 95%;
    background-color: #EDF6FF;
    padding: 15px;
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      > div {
        color: #999999;
        margin-top: 10px;
      }
    }
  }
  .list {
    width: 96%;
    margin: 20px 0;
  }
}
</style>