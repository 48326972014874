<!--  -->
<template>
  <div class='kc-list'>
    <div v-for="(item, index) in list" :key="index">
      <img :src="item.image" alt="">
      <div class="content">
        <div class="nav">
          <h4>{{item.keshi_name}}</h4>
          <el-tag size="mini" v-if="item.finish == '未完成'">{{item.finish}}</el-tag>
          <el-tag size="mini" type="warning" v-if="item.finish == '未开始'">{{item.finish}}</el-tag>
          <el-tag size="mini" type="success" v-if="item.finish == '已完成'">{{item.finish}}</el-tag>
        </div>
        <span>类别：公需科目</span>
        <span>学时：{{item.shichang | setTime}}分钟</span>
        <span>时间：{{item.add_time}}</span>
        <div class="plan">
          <span>进度：{{item.jindu || 0}}%</span>
          <el-progress :percentage="item.jindu || 0" color="#06B487" :show-text="false" style="width: 200px" :stroke-width="10"></el-progress>
        </div>
      </div>
      <div class="btn">
        <el-button type="warning" plain round size="mini" @click="handleStudy(index)" v-if="item.yunxu == 1">开始学习</el-button>
        <el-button type="info" plain round size="mini" disabled v-else>开始学习</el-button>
      </div>
    </div>
    <div class="hint">暂无数据</div>

    <el-dialog
      title="请下载谷歌浏览器观看视频"
      :visible.sync="dialogVisible"
      width="30%">
      <div @click="handleOpen()" class="upload">
        <img src="../../assets/img/guge.png" alt="">
        <div>电脑学习：<span>下载谷歌浏览器</span></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Api from '../../api/gongxu'

export default {
  components: {
    
  },
  props: {
    list: {
      type: Array,
      default () {
        return []
      }
    },
    year: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      dialogVisible: false
    };
  },
  filters: {
    setTime (value) {
      return Math.round(value/60)
    }
  },
  computed: {},
  watch: {
    
  },
  created() {
    
  },
  mounted() {
    
  },
  //方法集合
  methods: {
    handleStudy (i) {
      Api.getAdmin({
        vid: this.list[i].id
      }).then(res => {
        if (res.data.state == 0) {
          this.$message.warning('不可同时播放多个视频!')
          return;
        }
        if (document.documentMode && this.list[i].finish == '未完成') {
          this.dialogVisible = true
          return;
        }
        this.$router.push({name: 'Gongxu_video', query: {id: this.list[i].id, year: this.year}})
      })
    }
  },
}
</script>

<style lang='less' scoped>
.kc-list {
  max-height: 800px;
  overflow-y: auto;
  padding-right: 20px;
  &::-webkit-scrollbar{
    width:5px;
    height:5px;
    /**/
  }
  &::-webkit-scrollbar-track{
    background: rgb(239, 239, 239);
    border-radius:2px;
  }
  &::-webkit-scrollbar-thumb{
    background: #bfbfbf;
    border-radius:10px;
  }
  &::-webkit-scrollbar-thumb:hover{
    background: #333;
  }
  &::-webkit-scrollbar-corner{
    background: #179a16;
  }
  > div {
    display: flex;
    justify-content: space-between;
    padding: 30px 0;
    border-bottom: 1px solid #d8d8d8;
    > img {
      max-height: 150px;
    }
    .content {
      flex: 1;
      display: flex;
      margin: 0 20px;
      flex-direction: column;
      .nav {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        h4 {
          margin-right: 10px;
        }
      }
      > span {
        margin-bottom: 10px;
        color: #999;
        font-size: 14px;
      }
      .plan {
        display: flex;
        align-items: center;
        span {
          margin-right: 20px;
        }
      }
    }
    .btn {
      display: flex;
      align-items: flex-end;
    }
  }
  > .hint {
    width: 100%;
    display: block;
    border: 0;
    color: #999;
    text-align: center;
  }

  .upload {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    > img {
      width: 80px;
      height: 80px;
    }
    > div {
      margin-left: 5px;
      font-size: 18px;
      font-weight: bold;
      span {
        font-size: 18px;
        font-weight: bold;
        color: red;
      }
    }
  }
}
</style>