<!--  -->
<template>
  <div class='xuexi round bj-col-white pad'>
    <div class="heard">
      <h3>专业课程<span>(学习)</span></h3>
    </div>
    <div class="nav">
      <div class="prompt">
        <div>学时：根据人社部《专业技术人员继续教育规定》关于专业科目学习要求，所选课程不少于60学时。</div>
        <div>学分：专业科目学完一门考一门，学完考完授予20学分，方可打印学分证明。</div>
      </div>
      <div class="code">
        <img src="../../assets/img/code/study-h5.png" alt="">
        <span>手机学习请扫公众号二维码</span>
      </div>
    </div>

    <div class="list">
      <div v-for="(item, index) in list" :key="index">
        <div>
          <div class="content">
            <h2>{{item.year}}年{{item.leixing}}</h2>
            <div>继续教育专业科目</div>
            <span>课时{{item.keshi}}学时</span>
          </div>
          <div>
            <span>总进度：{{item.jindu}}%</span>
            <el-progress :percentage="item.jindu" color="#06B487" :show-text="false" :stroke-width="10" style="width: 180px;"></el-progress>
          </div>
        </div>
        <div>
          <!-- <el-button round type="success" plain class="self-button--success" size="mini" @click="handleExam(item.year)">药师考试</el-button> -->
          <el-button round type="success" class="self-button--success" size="mini" @click="handleStudy(item.year)">开始学习</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MApi from '../../api/my'

export default {
  components: {
  },
  data() {
    return {
      userInfo: {},
      list: [],
      rote: 30,
    };
  },
  computed: {},
  watch: {
    
  },
  created() {
    this.userInfo = localStorage.getItem("userInfo")
    this.userInfo = JSON.parse(this.userInfo)
    this.getFapiaoArr()
  },
  mounted() {
    this.$confirm('<div style="text-align:left">1、从2021年开始继续教育学分组成：专业科目20学分，公需科目10学分（免费），两者都需要取得学分才完成继续教育。<br/>2、专业科目学完一门考一门，学完考完授予学分，公需课边学边弹题学完授予学分。<br/>3、开具发票，可在发票管理或申请发票，5个工作日内发到邮箱。</div>', '重要提示', {
      dangerouslyUseHTMLString: true,
      center: true,
      showCancelButton: false,
      confirmButtonText: '知道了',
    })
  },
  //方法集合
  methods: {
    getFapiaoArr () {
      MApi.getFapiaoArr({
        type: 2
      }).then(res => {
        if (res.data.code == 1) {
          this.list = res.data.res
        } else {
          this.$message.error("课程列表获取失败")
        }
      })
    },
    handleStudy (year) {
      localStorage.setItem("year", year)
      this.setUserInfo()
      this.$router.push({name: 'Xuexi'})
    },
    handleExam (year) {
      localStorage.setItem("year", year)
      this.setUserInfo()
      this.$router.push({name: 'Xuankao'})
    },
  },
}
</script>

<style lang='less' scoped>
.xuexi {
  .heard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
      font-size: 18px;
    }
    span {
      font-size: 16px;
      display: inline-block;
      margin-left: 5px;
    }
  }
  .nav {
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0 10px;
    .tabs {
      width: 70%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .tab {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        .icon {
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(20%, -20%);
          color: @default;
        }
      }
      .line {
        width: 50px;
        height: 2px;
        background-color: @default;
      }
      .ban {
        background-color: #E5E5E5;
      }
    }
    .code {
      padding: 30px 0 30px 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-left: 1px solid #D8D8D8;
      img {
        width: 120px;
      }
    }
  }
  .prompt {
    margin-bottom: 30px;
    > div {
      margin-top: 10px;
      span {
        color: red;
      }
    }
  }
  .list {
    padding: 10px 20px 30px;
    border-top: 1px solid #f3f3f3;
    > div {
      padding: 20px 0;
      border-bottom: 1px solid #f3f3f3;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      > div:first-child {
        display: flex;
        align-items: flex-end;
        > div:last-child {
          span {
            display: inline-block;
            margin-bottom: 10px;
            margin-right: 10px;
          }
        }
      }
      .content {
        margin-right: 20px;
        background-image: url("../../assets/img/beij.png");
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        width: 360px;
        height: 220px;
        padding: 0 35px;
        h2, > div {
          color: @default;
          margin-bottom: 15px;
        }
        > div {
          font-size: 22px;
          white-space: wrap;
          word-break: break-all;
          text-align: center;
        }
        span {
          width: 40%;
          height: 25px;
          line-height: 25px;
          border-radius: 12px;
          text-align: center;
          background-color: @default;
          color: #f3f3f3;
          font-size: 14px;
        }
      }
    }
  }
}
</style>